import { graphql } from "gatsby"
import React from "react"
import { Layout } from "../components/Layout/Layout"
import sanitizeHtml from "sanitize-html"
import { PageTitle } from "../components/PageTitle/PageTitle"

interface PersonalTrainingProps {
  data: {
    cms: {
      page: {
        title: string
        fields: {
          image: {
            sourceUrl: string
          }
          text: string
        }
      }
    }
  }
}

export const PersonalTraining: React.FC<PersonalTrainingProps> = ({ data }) => {
  const { fields, title } = data.cms.page
  return (
    <Layout withPadding>
      <div className="flex w-full flex-col items-center whitespace-pre-line bg-homepageBackground px-6 md:w-full lg:px-28">
        <PageTitle>{title}</PageTitle>
        <div className="flex w-full flex-col items-center font-titilliumWeb md:w-10/12 md:content-between lg:w-full lg:flex-row lg:items-start">
          <div className="w-full font-normal leading-7">
            <div
              className="personal-training"
              dangerouslySetInnerHTML={{ __html: sanitizeHtml(fields.text) }}
            />
          </div>
          <img
            className="py-12 md:ml-6 lg:w-6/12 lg:py-2"
            src={fields.image.sourceUrl}
            alt={""}
          />
        </div>
      </div>
    </Layout>
  )
}
export default PersonalTraining
export const query = graphql`
  query MyQueryPersonalTraining {
    cms {
      page(id: "/personal-training-2", idType: URI) {
        title
        fields {
          text
          image {
            sourceUrl
          }
        }
      }
    }
  }
`
